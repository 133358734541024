import React, { PropsWithChildren, Fragment, ReactNode } from 'react'
import { UIBackdrop, UIButton, UIButtonIcon } from '@ui/components'
import { Transition, Dialog } from '@headlessui/react'
import { UICloseIcon } from '@ui/icons'
import { useIntl } from 'react-intl'
import intl from './intl'
import clsx from 'clsx'

interface UIModalProps extends PropsWithChildren {
    open: boolean
    onClose: () => void
    title?: ReactNode
    slide?: 'wide' | boolean
    buttons?: any
}

export const UIModal = (
    { open, onClose, title, buttons, slide, children }: UIModalProps
) => {
    const { formatMessage } = useIntl()

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-1000" onClose={() => onClose()}>
                <Transition.Child
                    enter="ec-transition-opacity ec-ease-in-out ec-duration-200"
                    enterFrom="ec-opacity-0"
                    enterTo="ec-opacity-100"
                    leave="ec-transition-opacity ec-ease-in-out ec-duration-200"
                    leaveFrom="ec-opacity-100"
                    leaveTo="ec-opacity-0">
                    <UIBackdrop/>
                </Transition.Child>
                <div className={clsx(
                    'ec-z-[1000] ec-fixed ec-inset-0 ec-flex ec-w-screen',
                    { 'lg:ec-items-center lg:ec-justify-center': !slide }
                )}>
                    <Dialog.Panel className={clsx({
                        'ec-w-0': !!slide
                    })}>
                        <Transition.Child
                            enter="ec-absolute lg:ec-static ec-transition-all ec-ease-in-out ec-duration-200"
                            enterFrom={clsx(
                                'ec-translate-x-[100vw] ec-translate-y-0',
                                {
                                    'lg:ec-translate-x-0 lg:ec-translate-y-[-50vh] lg:ec-opacity-0': !slide,
                                    'lg:ec-translate-x-[100vw] lg:ec-translate-y-0': slide
                                })}
                            enterTo={clsx(
                                'ec-translate-x-[50px] ec-translate-y-0',
                                {
                                    'lg:ec-translate-x-0 lg:ec-opacity-100': !slide,
                                    'lg:ec-translate-x-[calc(100vw-440px)] lg:ec-translate-y-0': slide === true,
                                    'lg:ec-translate-x-[200px] lg:ec-translate-y-0': slide === 'wide'
                                })}
                            entered="ec-absolute lg:ec-static"
                            leave="ec-absolute lg:ec-static ec-transition-all ec-ease-in-out ec-duration-200"
                            leaveFrom={clsx(
                                'ec-translate-x-[50px] ec-translate-y-0',
                                {
                                    'lg:ec-translate-x-0 lg:ec-translate-y-[-50%] lg:ec-opacity-100': !slide,
                                    'lg:ec-translate-x-[calc(100vw-440px)] lg:ec-translate-y-0': slide === true,
                                    'lg:ec-translate-x-[200px] lg:ec-translate-y-0': slide === 'wide'
                                })}
                            leaveTo={clsx(
                                'ec-translate-x-[100vw] ec-translate-y-0',
                                {
                                    'lg:ec-translate-x-0 lg:ec-translate-y-[-50vh] lg:ec-opacity-0': !slide,
                                    'lg:ec-translate-x-[100vw] lg:ec-translate-y-0': slide
                                })}>
                            <div className={clsx(
                                'ec-flex ec-flex-col ec-h-screen ec-bg-white ec-w-[calc(100vw-50px)] lg:ec-shadow-md',
                                {
                                    'lg:ec-w-[500px] lg:ec-h-fit lg:ec-max-h-[80vh]': !slide,
                                    'lg:ec-w-[440px]': slide === true,
                                    'lg:ec-w-[calc(100vw-200px)]': slide === 'wide'
                                })}>
                                <div className="ec-flex ec-align-top ec-p-4 ec-border-b ec-border-gray-lighter">
                                    <Dialog.Title className={clsx(
                                        'ec-text-xl ec-mb-0 ec-mr-4 ec-text-ellipsis ec-overflow-hidden',
                                        'ec-whitespace-nowrap'
                                    )}>
                                        {title}
                                    </Dialog.Title>
                                    <div className="ec-ml-auto ec-p-1 -ec-m-2 ec-hidden lg:ec-flex">
                                        <UIButtonIcon ariaLabel={formatMessage(intl.close)}
                                                      onClick={() => onClose()}>
                                            <UICloseIcon/>
                                        </UIButtonIcon>
                                    </div>
                                </div>
                                    <Dialog.Description as="div" className="ec-overflow-y-scroll ec-p-4">
                                        {children}
                                    </Dialog.Description>
                                    {buttons && !!buttons.length &&
                                        <div className="ec-p-4 ec-border-t ec-border-gray-lighter">
                                            <div className="ec-flex ec-gap-4 ec-justify-end">
                                                {buttons.map((button, index) =>
                                                    <UIButton key={index} variant="text" {...button} />
                                                )}
                                            </div>
                                        </div>
                                    }
                            </div>

                        </Transition.Child>
                        <Transition.Child
                            enter="ec-absolute lg:ec-hidden ec-transition-all ec-ease-in-out ec-duration-200"
                            enterFrom="ec-translate-x-[-50px] ec-translate-y-0"
                            enterTo="ec-translate-x-0 ec-translate-y-0"
                            entered="ec-absolute lg:ec-hidden"
                            leave="ec-absolute lg:ec-hidden ec-transition-all ec-ease-in-out ec-duration-200"
                            leaveFrom="ec-translate-x-0 ec-translate-y-0"
                            leaveTo="ec-translate-x-[-50px] ec-translate-y-0">
                            <div className={clsx(
                                'ec-w-[50px] ec-h-screen ec-bg-black ec-flex ec-justify-center ec-items-start ec-p-4'
                            )}>
                                <div className="ec-p-1 -ec-m-2">
                                    <UIButtonIcon ariaLabel={formatMessage(intl.close)}
                                                  color="light"
                                                  onClick={() => onClose()}>
                                        <UICloseIcon/>
                                    </UIButtonIcon>
                                </div>
                            </div>
                        </Transition.Child>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition>
    )
}
