import React, {useEffect, useState} from 'react';
import clsx from "clsx";

export const ItemList = ({itemData, setSelectedKey, selectedKey, visibleData}) => {

    const handleSelect = key => {
        setSelectedKey(key);
    };

    useEffect(() => {
        if(!selectedKey){
            setSelectedKey(Object.keys(visibleData)[0])
        }
    }, [selectedKey])

    const itemElements = Object.entries(itemData).map(([key, value]) => {

        if(!visibleData[key]) return null

        return (
        <div
            onClick={() => handleSelect(key)}
            className={clsx('cursor-pointer ec-p-1 ec-border ec-border-gray-lighter ec-m-1', selectedKey === key && 'ec-bg-gray-lighter')}
        >
            {visibleData[key]}
        </div>
    )});

    return (
        <div className={'ec-flex'}>
            {itemElements}
        </div>
    );
};
