import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { GraphQlResponse } from '@model/api'

export const request = <T>(
    query: string,
    variables?: { [key: string]: any },
    config?: AxiosRequestConfig
): Promise<AxiosResponse<GraphQlResponse<T>>> => (
    axios.post<GraphQlResponse<T>>('/graphql', { query, variables }, {
        ...(config || {})
    })
)
