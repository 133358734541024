import { MessageDescriptor } from 'react-intl'

export interface UIChipIntl {
    close?: MessageDescriptor
}

export default {
    close: {
        id: 'ui.chip.close',
        defaultMessage: 'Close'
    }
}
