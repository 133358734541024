import React, { HTMLAttributes, MouseEvent } from 'react'
import { UICloseIcon } from '@ui/icons'
import { useIntl } from 'react-intl'
import intl, { UIChipIntl } from './intl'
import clsx from 'clsx'

interface UIChipProps extends UIOmit<HTMLAttributes<HTMLDivElement>> {
    label: string
    disabled?: boolean
    onClose?: (event: MouseEvent<HTMLButtonElement>) => void
    messages?: UIChipIntl
}

export const UIChip = (
    { label, disabled, onClose, messages, ...attributes }: UIChipProps
) => {
    const { formatMessage } = useIntl()

    const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
        !disabled && onClose(e)
    }

    return (
        <div className={clsx(
            'ec-inline-flex ec-text-white ec-items-center ec-text-center ec-rounded-full ec-text-sm',
            'ec-leading-none ec-py-1.5 ec-px-3',
            {
                'ec-bg-red-dark': !disabled,
                'ec-cursor-not-allowed ec-bg-gray-dark': disabled
            }
        )} {...attributes}>
            <span className="ec-leading-5">{label}</span>
            {onClose &&
                <button type="button" onClick={handleClose} className={clsx(
                    'ec-text-white ec-opacity-60 ec-ml-1 -ec-mr-1',
                    {
                        'hover:ec-opacity-100': !disabled,
                        'ec-cursor-not-allowed': disabled
                    }
                )}>
                    <UICloseIcon/>
                    <span className="ec-sr-only">{formatMessage(messages?.close || intl.close)}</span>
                </button>
            }
        </div>
    )
}
