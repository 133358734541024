import React, { HTMLAttributes } from 'react'
import clsx from 'clsx'

interface UIStackProps extends UIOmit<HTMLAttributes<HTMLDivElement>> {
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
    spacing?: 'none' | 'small' | 'medium' | 'large' | 'extra-large'
    wrap?: boolean
}

const directionVariants = {
    'row': 'ec-flex-row',
    'column': 'ec-flex-col',
    'row-reverse': 'ec-flex-row-reverse',
    'column-reverse': 'ec-flex-col-reverse'
}

const spacingVariants = {
    none: '',
    small: 'child:ec-mb-2',
    medium: 'child:ec-mb-4',
    large: 'child:ec-mb-6',
    'extra-large': 'child:ec-mb-8'
}

export const UIStack = (
    { direction = 'column', spacing = 'medium', wrap, children, ...attributes }: UIStackProps
) => {
    return (
        <div className={clsx(
            'ec-flex',
            spacingVariants[spacing],
            directionVariants[direction],
            {
                'last:child:ec-mb-0': spacing !== 'none',
                'flex-wrap': wrap
            }
        )} {...attributes}>
            {children}
        </div>
    )
}
