import React, {PropsWithChildren} from 'react';

interface UIFixedFooterProps extends PropsWithChildren {
    visible?: boolean
}

export const UIFixedFooter = ({visible, children, ...attributes}: UIFixedFooterProps) => {

    if(!visible) return null;

    return (
        <footer {...attributes}
            className="ec-fixed ec-bottom-0 ec-left-0 ec-w-full ec-bg-white ec-z-[200]
            ec-px-8 ec-py-4 ec-shadow-[0_-3px_5px_-2px_rgba(0,0,0,0.3)]">
            {children}
        </footer>
    );
}