import React, { PropsWithChildren, ReactNode } from 'react'
import clsx from 'clsx'

export type UIListItemProps = {
    label: ReactNode
    value: ReactNode
    hide?: boolean
}

export const UIDataList = (
    { children }: PropsWithChildren
) => (
    <dl className="ec-grid ec-grid-cols-list ec-gap-y-1 ec-gap-x-3">
        {children}
    </dl>
)

UIDataList.Item = (
    { label, value, hide = false }: UIListItemProps
) => {
    if (hide || value === null || value === undefined || value === '') return null

    return (
        <>
            <dt className="ec-text-sm ec-font-medium ec-text-black/[0.55]">{label}</dt>
            <dd className={clsx('ec-text-sm ec-min-w-0 ec-break-words')}>{value}</dd>
        </>
    )
}
