import React, { JSX, HTMLAttributes } from 'react'

interface UIFieldsetProps extends UIOmit<HTMLAttributes<HTMLFieldSetElement>> {
    legend?: string | JSX.Element
}

export const UIFieldset = (
    { legend, children, ...attributes }: UIFieldsetProps
) => (
    <fieldset className="ec-grid ec-grid-cols-1 ec-gap-x-4 ec-gap-y-4 sm:ec-grid-cols-6 ec-mb-4 last:ec-mb-0"
              {...attributes}>
        {legend &&
            <legend className="ec-text-gray-darker ec-text-xl ec-font-normal ec-font-sans ec-mb-4">
                {legend}
            </legend>
        }
        {children}
    </fieldset>
)
