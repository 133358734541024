import React from 'react'
import { useFormContext } from 'react-hook-form'
import { usePreferencesState } from '@module/CustomerPreferences'
import { IMethodsType } from '@module/CustomerPreferences/services'
import { UIFieldset, UIFieldsetSpan, UIRadio, UIRadioGroup } from '@ui/components'
import { useIntl } from 'react-intl'

export interface DeliveryMethodProps {
    methods: IMethodsType[]
}

export const DeliveryMethods = (
    { methods }: DeliveryMethodProps
) => {
    const { register } = useFormContext()
    const { isLoading } = usePreferencesState()
    const { formatMessage } = useIntl()

    if (!methods) return null

    return (
        <UIFieldset legend={formatMessage({
            id: 'deliveryMethods.defaultMethod',
            defaultMessage: 'Default Delivery Method'
        })}>
            <UIFieldsetSpan>
                <UIRadioGroup>
                    {methods.map(method =>
                        <UIRadio key={method.code}
                                 register={register('checkout.default_delivery_method')}
                                 disabled={isLoading}
                                 value={method.code}
                                 label={method.title}/>
                    )}
                </UIRadioGroup>
            </UIFieldsetSpan>
        </UIFieldset>
    )
}
