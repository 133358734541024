import React, { MouseEvent, ButtonHTMLAttributes, forwardRef } from 'react';
import { UILoaderIcon } from '@ui/icons';
import clsx from 'clsx';

interface UIButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    size?: 'small' | 'medium' | 'large';
    variant?: 'contained' | 'outlined' | 'text';
    fullWidth?: boolean;
    loading?: boolean;
    loadingLabel?: string;
}

const activeVariants = {
    contained: 'ec-bg-blue-dark ec-text-white ec-shadow-md hover:ec-bg-blue hover:ec-text-white ec-px-4 ec-py-1',
    outlined: 'ec-bg-white ec-text-blue-dark ec-shadow-md ec-shadow-gray-300 ec-border ec-border-blue-dark ec-border-solid ec-px-4-1px ec-py-1-1px hover:ec-bg-blue hover:ec-text-white',
    text: 'ec-bg-transparent ec-text-blue-light ec-shadow-none hover:ec-bg-blue-lighter hover:ec-text-blue-light ec-px-4 ec-py-1'
};

const disabledVariants = {
    contained: 'ec-bg-gray-lighter ec-cursor-not-allowed ec-text-gray-light hover:ec-text-gray-light ec-px-4 ec-py-1',
    outlined: 'ec-bg-gray-lighter ec-cursor-not-allowed ec-text-gray-light hover:ec-text-gray-light ec-px-4 ec-py-1',
    text: 'ec-bg-transparent ec-cursor-not-allowed ec-text-gray-light hover:ec-text-gray-light ec-px-4 ec-py-1'
};

const sizeVariants = {
    small: 'ec-text-xs ec-leading-5 ec-min-h-[1.75rem]',
    medium: 'ec-text-sm ec-leading-7 ec-min-h-[2.25rem]',
    large: 'ec-text-sm ec-leading-8 ec-min-h-[2.5rem]'
};

export const UIButtonRef = forwardRef<HTMLButtonElement, UIButtonProps>(
    ({
         size = 'medium',
         variant = 'contained',
         fullWidth = false,
         loading = false,
         loadingLabel,
         children,
         ...attributes
     }, ref) => {
        const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
            if (!attributes.disabled && attributes.onClick) {
                attributes.onClick(event);
            }
        };

        const buttonStyle = ref && 'current' in ref && ref.current && loading
            ? { minWidth: ref.current.getBoundingClientRect().width }
            : null;

        return (
            <button ref={ref}
                    type="button"
                    style={buttonStyle}
                    className={clsx(
                        'ec-uppercase ec-inline-flex ec-justify-center ec-items-center ec-tracking-wider',
                        'ec-transition-all ec-ease-in-out ec-duration-200',
                        `hover:ec-no-underline ${sizeVariants[size]}`,
                        !attributes.disabled && activeVariants[variant],
                        attributes.disabled && disabledVariants[variant],
                        fullWidth && 'ec-w-full'
                    )}
                    onClick={handleClick}
                    {...attributes}>
                {loading && !loadingLabel && <UILoaderIcon />}
                {loading && !!loadingLabel && <span>{loadingLabel}</span>}
                {!loading && children}
            </button>
        );
    }
);
