import React, { AnchorHTMLAttributes, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

interface UIButtonLinkProps extends UIOmit<AnchorHTMLAttributes<HTMLAnchorElement>> {
    size?: 'small' | 'medium' | 'large'
    variant?: 'contained' | 'outlined' | 'text'
    fullWidth?: boolean
    disabled?: boolean
    app?: boolean
}

const activeVariants = {
    contained: 'ec-bg-blue-dark ec-text-white ec-shadow-md hover:ec-bg-blue hover:ec-text-white ec-px-4 ec-py-1',
    outlined: 'ec-bg-white text-blue-dark ec-shadow-md ec-shadow-gray-300 ec-border ec-border-blue-dark' +
        'ec-border-solid ec-px-4-1px ec-py-1-1px hover:ec-bg-blue hover:ec-text-white',
    text: 'ec-bg-transparent ec-text-blue-light ec-shadow-none hover:ec-bg-blue-lighter hover:ec-text-blue-light' +
        'ec-px-4 ec-py-1'
}

const disabledVariants = {
    contained: 'ec-bg-gray-lighter ec-cursor-not-allowed ec-text-gray-light hover:ec-text-gray-light' +
        'active:ec-text-gray-light focus:ec-text-gray-light ec-px-4 ec-py-1',
    outlined: 'ec-bg-gray-lighter ec-cursor-not-allowed ec-text-gray-light hover:ec-text-gray-light' +
        'active:ec-text-gray-light focus:ec-text-gray-light ec-px-4 ec-py-1',
    text: 'ec-bg-transparent ec-cursor-not-allowed ec-text-gray-light hover:ec-text-gray-light' +
        'active:ec-text-gray-light focus:ec-text-gray-light ec-px-4 ec-py-1'
}

const sizeVariants = {
    small: 'ec-text-xs ec-leading-5 ec-min-h-[1.75rem]',
    medium: 'ec-text-sm ec-leading-7 ec-min-h-[2.25rem]',
    large: 'ec-text-sm ec-leading-8 ec-min-h-[2.5rem]'
}

export const UIButtonLink = (
    {
        size = 'medium',
        variant = 'contained',
        fullWidth = false,
        disabled = false,
        app = true,
        children,
        ...attributes
    }: UIButtonLinkProps
) => {
    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
        disabled && event.preventDefault()
    }

    if (app) {
        return (
            <Link to={attributes.href} className={clsx(
                'ec-uppercase ec-inline-flex ec-justify-center ec-items-center ec-tracking-wider ec-transition-all',
                `ec-duration-300 hover:ec-no-underline ${sizeVariants[size]}`,
                !disabled && activeVariants[variant],
                disabled && disabledVariants[variant],
                fullWidth && 'ec-w-full'
            )} onClick={handleClick}>
                {children}
            </Link>
        )
    }

    return (
        <a className={clsx(
            'ec-uppercase ec-inline-flex ec-justify-center ec-items-center ec-tracking-wider ec-transition-all',
            `ec-duration-300 hover:ec-no-underline ${sizeVariants[size]}`,
            !disabled && activeVariants[variant],
            disabled && disabledVariants[variant],
            fullWidth && 'ec-w-full'
        )} onClick={handleClick}
           {...attributes}>
            {children}
        </a>
    )
}
