import axios from 'axios';
import { GraphQlResponseInterface } from '@model/api';

export const request = <GraphQlResponseData>(
    query: string,
    variables?: { [key: string]: any }
): Promise<GraphQlResponseInterface<GraphQlResponseData>> => {
    return axios.post('/graphql', { query, variables })
        .then(response => response.data as GraphQlResponseInterface<GraphQlResponseData>)
        .catch(error => {
            console.error('There was an error!', error);
            return Promise.reject(error);
        });
}
