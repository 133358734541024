import { MessageDescriptor } from 'react-intl'

export interface UIModalIntl {
    close: MessageDescriptor
}

export default {
    close: {
        id: 'ui.modal.close',
        defaultMessage: 'Close'
    }
}
