import { usePreferencesState } from '@module/CustomerPreferences'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { UIFieldset, UIFieldsetSpan, UIInputChip, UIToggle } from '@ui/components'
import { useYup } from '@module/Yup'
import { UIChipItem } from '@ui/hooks'
import { ValidationError } from 'yup'

export const OrderConfirmationEmails = () => {
    const { yup, errorMessage } = useYup()
    const { formatMessage } = useIntl()
    const { control, register, watch, setValue, formState: { errors } } = useFormContext()
    const { isLoading } = usePreferencesState()
    const enabledValue = watch('checkout.receive_order_confirmation')

    useEffect(() => {
        if (!enabledValue) {
            setValue('checkout.order_confirmation_emails', [])
        }
    }, [enabledValue])

    const validateEmail = (chip: UIChipItem) => {
        try {
            yup.object({
                email: yup.string().email()
            }).validateSync({ email: chip.id })
        } catch (e) {
            return errorMessage(e as ValidationError)
        }
    }

    return (
        <UIFieldset legend={formatMessage({
            id: 'orderConfirmation.title',
            defaultMessage: 'Order Confirmation Emails'
        })}>
            <UIFieldsetSpan>
                <UIToggle
                    register={register('checkout.receive_order_confirmation')}
                    name="checkout.receive_order_confirmation"
                    disabled={isLoading}
                    label={formatMessage({
                        id: 'orderConfirmation.sendEmail',
                        defaultMessage: 'Send Web Order Confirmation Email'
                    })}/>
            </UIFieldsetSpan>
            <UIFieldsetSpan>
                {enabledValue &&
                    <label className={'ec-text-gray-darker'}>
                        {formatMessage({
                            id: 'orderConfirmation.chips.title',
                            defaultMessage: 'Send order confirmation email to these additional people:'
                        })}
                    </label>
                }
                {enabledValue &&
                    <Controller
                        control={control}
                        name="checkout.order_confirmation_emails"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <UIInputChip disabled={isLoading}
                                         ref={ref}
                                         items={value}
                                         onBlur={onBlur}
                                         validate={validateEmail}
                                         onChange={data => onChange(data.map(datum => datum.id))}
                                         error={errorMessage(errors, 'checkout.order_confirmation_emails')}/>
                        )}/>
                }
            </UIFieldsetSpan>
        </UIFieldset>
    )
}

