import React, { HTMLAttributes } from 'react'
import { UILoaderIcon } from '@ui/icons'

export const UIPageLoader = (
    { ...attributes }: UIOmit<HTMLAttributes<HTMLDivElement>>
) => (
    <div className="ec-fixed ec-inset-0 ec-bg-white/60 ec-z-[9999] ec-flex ec-items-center ec-justify-center"
         {...attributes}>
        <UILoaderIcon size="extra-large"/>
    </div>
)
