import { QueryClient, QueryCache } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const getQueryClient = () => {
    if (window.queryClient) {
        return window.queryClient
    }

    window.queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: (error: AxiosError) => {
                console.error('API error', error.message)
            }
        }),
        defaultOptions: {
            queries: {
                staleTime: 600000 * 5, // 5 minutes
            },
        },
    })
    return window.queryClient
}
