import React from 'react'
import { UiIcon } from '@ui/types'

export const CloseIcon = (
    { size = 6 } : UiIcon
) => {
    return (
        <svg className="ec-fill-none ec-stroke-current ec-stroke-[1.5] ec-w-6 ec-h-6"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
    )
}
