const APP_STORAGE_KEY = 'app_data'

interface AppStorageData {
    ts: number
}

const getAppData = () => localStorage.getItem(window.appConfig.appStorageKey || APP_STORAGE_KEY)
    && JSON.parse(localStorage.getItem(window.appConfig.appStorageKey || APP_STORAGE_KEY))

export const appStorage = {
    getData: <T extends {}>(key: string): T & AppStorageData => {
        const appData = getAppData()
        return appData && appData[key] || undefined
    },
    setData: (key: string, data: { [key: string]: any }) => {
        const appData = getAppData() || {}
        data.ts = new Date().valueOf()

        localStorage.setItem(window.appConfig.appStorageKey || APP_STORAGE_KEY, JSON.stringify({
            ...appData,
            [key]: data
        }))
    }
}
