import React, { HTMLAttributes, JSX, MouseEvent } from 'react'
import { UIButton, UIButtonIcon } from '@ui/components'
import { UIDeleteIcon } from '@ui/icons'
import { useIntl } from 'react-intl'
import intl, { UIInputListIntl } from './intl'

type UIInputListField = {
    id: string,
    [key: string]: any
}

export interface UIInputListColumn {
    label: string
    renderer: (field: UIInputListField, index: number) => JSX.Element
}

interface UIInputListProps extends UIOmit<HTMLAttributes<HTMLTableElement>> {
    columns: UIInputListColumn[]
    data: UIInputListField[]
    onAdd: () => void
    onDelete: (params: {
        item: UIInputListField
        index: number
    }) => void
    limit?: number
    disabled?: boolean
    messages?: UIInputListIntl
}

export const UIInputList = (
    { columns, data, onAdd, onDelete, messages, disabled, limit = 0, ...attributes }: UIInputListProps
) => {
    const { formatMessage } = useIntl()
    const canAdd = limit === 0 || data && data.length < limit

    const handleAdd = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        onAdd()
    }

    return (
        <table className="ec-w-full" {...attributes}>
            <thead>
            {data && !!data.length &&
                <tr>
                    {columns.map(({ label }) => (
                        <th>{label}</th>
                    ))}
                </tr>
            }
            </thead>
            <tbody>
            {data.map((item, index) => (
                <tr>
                    {columns.map(({ renderer }) => (
                        <td className="ec-tw-align-top ec-align-top ec-pt-2 ec-pr-2">{renderer(item, index)}</td>
                    ))}
                    <td className="ec-align-top ec-pt-2 ec-text-blue-light ec-w-7">
                        <UIButtonIcon ariaLabel={formatMessage(messages?.delete || intl.delete)}
                                      disabled={disabled}
                                      onClick={() => onDelete({ item, index })}>
                            <UIDeleteIcon/>
                        </UIButtonIcon>
                    </td>
                </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={2} className="ec-pt-2">
                    <UIButton onClick={handleAdd} disabled={!canAdd || disabled}>
                        {formatMessage(messages?.add || intl.add)}
                    </UIButton>
                </td>
            </tr>
            </tfoot>
        </table>
    )
}
