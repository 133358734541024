import { MessageDescriptor } from 'react-intl'

export interface UseUIChipIntl {
    errorDefault: MessageDescriptor
    errorUnique: MessageDescriptor
}

export default {
    errorDefault: {
        id: 'ui.input.chip.error.default',
        defaultMessage: 'Item is not valid.'
    },
    errorUnique: {
        id: 'ui.input.chip.error.unique',
        defaultMessage: 'Item has already been added.'
    }
}
