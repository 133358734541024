import React, {
    KeyboardEvent,
    ClipboardEvent,
    useState,
    ChangeEvent,
    FocusEvent,
    useEffect,
    forwardRef,
    Ref, HTMLAttributes
} from 'react'
import { UIChip, UIChipItem, UIFormError, UIInputLabel, UIStack, useUIChip, UseUIChipProps } from '@module/Ui'
import { UseUIChipIntl } from '@ui/hooks'
import intl, { UIInputChipIntl } from './intl'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

interface UIInputChipProps extends UIOmit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    items?: (string | UIChipItem)[]
    label?: string,
    error?: string
    disabled?: boolean
    placeholder?: string
    submitKeys?: string[]
    validate?: UseUIChipProps['validate']
    onChange?: (data: UIChipItem[]) => void
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void
    messages?: UIInputChipIntl & UseUIChipIntl
}

export const UIInputChip = forwardRef((
    {
        items = [],
        label,
        error,
        disabled,
        placeholder,
        submitKeys = ['Tab', 'Enter', ',', ' '],
        validate,
        onChange,
        onBlur,
        messages,
        ...attributes
    }: UIInputChipProps
    , ref: Ref<HTMLInputElement>) => {
    const {
        chips,
        error: validationError,
        setError: setValidationError,
        setChips,
        addChip,
        removeChip
    } = useUIChip({ items, validate, messages })

    const [input, setInput] = useState('')
    const { formatMessage } = useIntl()

    useEffect(() => {
        setChips(items)
    }, [JSON.stringify(items)])

    useEffect(() => {
        onChange && onChange(chips)
    }, [JSON.stringify(chips)])

    useEffect(() => {
        setValidationError(error)
    }, [error])

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setInput(e.currentTarget.value)
        setValidationError(null)
    }

    const handleKeydown = (event: KeyboardEvent<HTMLInputElement>): void => {
        if (!input || 'key' in event && !submitKeys.includes(event.key)) return

        addChip(input) && setInput('')
        event.preventDefault()
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        input && addChip(input) && setInput('')
        onBlur && onBlur(event)
        event.preventDefault()
    }

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
        const input: string = event.clipboardData.getData('text')
        addChip(input) || setInput(input)
        event.preventDefault()
    }

    return (
        <div className={clsx(
            `ec-relative`,
            { 'ec-mt-4': !!label }
        )} {...attributes}>
            <div className={clsx(
                'ec-border ec-border-solid ec-border-gray-dark',
                {
                    'ec-bg-white': !disabled,
                    'ec-bg-gray-lighter ec-cursor-not-allowed': disabled
                }
            )}>
                <UIStack direction="row" spacing="none" wrap>
                    {chips.map(chip => (
                        <div className="ec-m-[2px]">
                            <UIChip label={chip.label || chip.id}
                                    disabled={disabled || !!chip.disabled}
                                    onClose={() => removeChip(chip)}
                            />
                        </div>
                    ))}
                    <input className={clsx(
                        'ec-text-sm ec-leading-7 ec-h-[2.25rem] ec-ml-2 ec-w-[200px] ec-flex-auto ec-peer',
                        { 'ec-cursor-not-allowed': disabled }
                    )}
                           ref={ref}
                           value={input}
                           disabled={disabled}
                           placeholder={!disabled
                               ? placeholder || formatMessage(messages?.placeholder || intl.placeholder)
                               : ''}
                           onChange={handleChange}
                           onKeyDown={handleKeydown}
                           onBlur={handleBlur}
                           onPaste={handlePaste}
                    />
                </UIStack>
            </div>
            <UIInputLabel label={label} active/>
            <UIFormError error={validationError}/>
        </div>
    )
})
