import React, { HTMLAttributes } from 'react'
import { UIFormError, UIStack } from '@ui/components'

interface UIRadioGroupProps extends UIOmit<HTMLAttributes<HTMLDivElement>> {
    error?: string
}

export const UIRadioGroup = (
    { children, error, ...attributes }: UIRadioGroupProps
) => (
    <UIStack spacing="small" {...attributes}>
        {children}
        <UIFormError error={error}/>
    </UIStack>
)
