import React, { InputHTMLAttributes } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import clsx from 'clsx'

interface UIRadioProps extends UIOmit<InputHTMLAttributes<HTMLInputElement>> {
    label?: string
    register?: UseFormRegisterReturn<string>
}

export const UIRadio = (
    {
        label,
        register,
        ...attributes
    }: UIRadioProps
) => (
    <label className="ec-inline-flex ec-cursor-pointer ec-col-span-full">
        <input type="radio"
               className="ec-sr-only ec-peer"
               {...register || {}}
               {...attributes}/>
        <span className={clsx(
            'ec-text-sm ec-inline-flex ec-items-center before:ec-mr-2 before:ec-inline-block',
            'before:ec-transition-all before:ec-ease-in-out before:ec-duration-200',
            'before:ec-w-[18px] before:ec-h-[18px] before:ec-border-[2px] before:ec-rounded-full',
            'before:ec-border-gray-dark peer-checked:before:ec-border-[6px]',
            {
                'peer-checked:before:ec-border-blue-light before:ec-bg-white': !attributes.disabled,
                'ec-cursor-not-allowed peer-checked:before:ec-border-gray-dark before:ec-bg-gray-lighter': attributes.disabled
            }
        )}>
            {label}
        </span>
    </label>
)
