import React, { HTMLAttributes } from 'react'
import clsx from 'clsx'

export const UIContainerSide = (
    { children, ...attributes }: UIOmit<HTMLAttributes<HTMLDivElement>>
) => (
    <div className={clsx(
        'ec-mb-6 ec-items-stretch',
        'xl:ec-mb-0 xl:ec-basis-[360px] xl:ec-min-w-[300px] xl:ec-flex-shrink xl:ec-ml-6'
    )} {...attributes}>
        {children}
    </div>
)
