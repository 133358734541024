import { usePreferencesState } from '@module/CustomerPreferences'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { UIFieldset, UIFieldsetSpan, UIInput, UIPanel, UIPanelBody } from '@ui/components'
import { useYup } from '@module/Yup'

export const ResidioProPerksNumber = () => {
    const { formatMessage } = useIntl()
    const { errorMessage } = useYup()
    const { isLoading } = usePreferencesState()
    const { register, formState: { errors }, watch, setValue } = useFormContext()

    const emptyValue = watch('residioProPerks.value')

    useEffect(() => {
        if (emptyValue === '0') {
            setValue('residioProPerks.value', '')
        }
    }, [])

    return (
        <UIPanel>
            <UIPanelBody>
                <UIFieldset legend={formatMessage({
                    id: 'residioProPerks.title',
                    defaultMessage: 'Resideo Pro Perks Number'
                })}>
                    <UIFieldsetSpan>
                        <UIInput
                            register={register('residioProPerks.value')}
                            type={'number'}
                            value={emptyValue}
                            name={'residioProPerks.value'}
                            disabled={isLoading}
                            label={formatMessage({
                                id: 'residioProPerks.value.label',
                                defaultMessage: 'Resideo Pro Number'
                            })}
                            error={errorMessage(errors, 'residioProPerks.value')}/>
                    </UIFieldsetSpan>
                </UIFieldset>
            </UIPanelBody>
        </UIPanel>
    )
}
