import React from 'react'
import { UIIconProps } from '../types'
import { sizeVariants } from './variants'

export type UILoaderIconProps = UIIconProps & {
    color?: 'default' | 'light'
}

const colorVariants = {
    default: 'ec-fill-blue-light',
    light: 'ec-fill-white'
}

export const UILoaderIcon = (
    { size = 'medium', color = 'default' }: UILoaderIconProps
) => (
    <svg className={`ec-animate-rotate-slow ${colorVariants[color]} ${sizeVariants[size]}`}
         viewBox="0 0 52 52">
        <path d="M0 26h6C6 15 15 6 26 6c6.1 0 11.8 2.8 15.6 7.5l4.7-3.8C41.4 3.6 34 0 26 0 11.6 0 0 11.6 0 26z"/>
        <path d="M50 17.8c.1.5-.4.9-.9.8l-11.9-2.5c-.5-.1-.6-.5-.2-.8l11.9-9.6c.4-.3.8-.2.8.4l.3 11.7z"/>
        <g>
            <path
                d="M52 26h-6c0 11-9 20-20 20-6.1 0-11.8-2.8-15.6-7.5l-4.7 3.8C10.6 48.4 18 52 26 52c14.4 0 26-11.6 26-26z"/>
            <path d="M2 34.2c-.1-.5.4-.9.9-.8l11.9 2.5c.5.1.6.5.2.8L3.1 46.3c-.4.3-.8.2-.8-.4L2 34.2z"/>
        </g>
    </svg>
)
