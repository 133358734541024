import React, { HTMLAttributes } from 'react'

interface UIGridItemProps extends UIOmit<HTMLAttributes<HTMLDivElement>> {
    span?: 1 | 2 | 3 | 4 | 5 | 'full'
}

const spanVariants = {
    1: 'sm:ec-col-span-1',
    2: 'sm:ec-col-span-2',
    3: 'sm:ec-col-span-3',
    4: 'sm:ec-col-span-4',
    5: 'sm:ec-col-span-5',
    full: 'ec-col-span-full'
}

export const UIGridItem = (
    { span = 'full', children, ...attributes }: UIGridItemProps
) => (
    <div className={spanVariants[span]} {...attributes}>
        {children}
    </div>
)
