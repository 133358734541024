import { useQuery, useQueryClient } from '@tanstack/react-query'
import { CustomerData, getCustomerData } from '@module/Customer'
import { appStorage } from '@model/storage'
import { useEffect, useRef } from 'react'

const QUERY_KEY = 'customer-data'

export const useCustomer = () => {
    const appData = appStorage.getData<CustomerData>('customer')
    const queryClient = useQueryClient()
    const queryRunning = useRef(false)

    const query = useQuery<CustomerData>({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            queryRunning.current = true
            return await getCustomerData()
        },
        initialData: appData,
        initialDataUpdatedAt: appData?.ts,
        staleTime: 6000 * 20 // 20 min
    })

    useEffect(() => {
        if (!query.isSuccess || !queryRunning.current) return
        queryRunning.current = false

        appStorage.setData('customer', query.data)
    }, [query.isSuccess])

    const invalidateData = () => (
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY] })
    )

    const setData = async (
        data: Partial<CustomerData>,
        cancelQueries = true
    ) => {
        cancelQueries && await queryClient.cancelQueries([QUERY_KEY])

        const previousData = queryClient.getQueryData([QUERY_KEY])
        queryClient.setQueryData([QUERY_KEY], (old: CustomerData) => ({ ...old, data }))

        return { previousData }
    }

    const isLoggedIn = !!query.data?.name

    return {
        ...query,
        queryClient,
        setData,
        isLoggedIn,
        invalidateData
    }
}
