import React, { ComponentPropsWithoutRef } from 'react'
import { UIOmit } from '../../../types'

export const UIPanel = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'div'>>
) => (
    <section className="ec-bg-white ec-border ec-border-gray-dark ec-w-full" {...attributes}>
        {children}
    </section>
)

UIPanel.Header = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'div'>>
) => (
    <div className="ec-px-4 ec-pt-4" {...attributes}>
        {children}
    </div>
)


UIPanel.Body = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'div'>>
) => (
    <div className="ec-p-4" {...attributes}>
        {children}
    </div>
)

UIPanel.Footer = (
    { children, ...attributes }: UIOmit<ComponentPropsWithoutRef<'div'>>
) => (
    <div className="ec-px-4 ec-pb-4" {...attributes}>
        {children}
    </div>
)
