import React, { createContext, PropsWithChildren, useContext } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { localeConfig } from '../config/locale'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { useIntl } from 'react-intl'
import intl from '../config/intl'
import * as yup from 'yup'

type YupType = typeof yup
type YupResolverType = typeof yupResolver

interface YupProviderProps extends PropsWithChildren {
    locale?: string
}

export interface YupProviderState {
    yup: YupType
    resolver: YupResolverType
    errorMessage: <T extends FieldErrors | yup.ValidationError>(
        errors: T,
        name?: T extends FieldErrors ? string : never
    ) => string | null
}

const YupProviderContext = createContext<YupProviderState | null>(null)

export const YupProvider = (
    { children }: YupProviderProps
) => {
    const { formatMessage } = useIntl()

    yup.setLocale(localeConfig)

    yup.addMethod(yup.number, 'nan', function () {
        return this.transform(value => isNaN(value) ? undefined : value)
    })

    yup.addMethod(yup.string, 'phone', function (msg?: string) {
        const message = msg || formatMessage(intl.stringPhoneNumber)

        return this.test('phone', message, value => (
            /^(\+1)?\d{10}$/.test(value.replace(/[^0-9\+]/g, ''))
        ))
    })

    yup.addMethod(yup.number, 'range', function (moreThan: number, lessThan: number, msg?: string) {
        const message = msg || formatMessage(intl.numberRange, { moreThan, lessThan })

        return this.test('range', message, value => (
            value > moreThan && value < lessThan
        ))
    })

    yup.addMethod(yup.number, 'rangeEqual', function (min: number, max: number, msg?: string) {
        const message = msg || formatMessage(intl.numberRangeEqual, { min, max })

        return this.test('rangeEqual', message, value => (
            value >= min && value <= max
        ))
    })

    yup.addMethod(yup.string, 'rangeLength', function (min: number, max: number, msg?: string) {
        const message = msg || formatMessage(intl.stringRangeLength, { min, max })

        return this.test('rangeLength', message, value => {
            return !value || value.length >= min && value.length <= max
        })
    })

    yup.addMethod(yup.array, 'unique', function(message, mapper = a => a) {
        return this.test('unique', message, function(list) {
            return list.length  === new Set(list.map(mapper)).size;
        });
    });

    const errorMessage = <T extends FieldErrors | yup.ValidationError>(
        errors: T,
        name?: T extends FieldErrors ? string : never
    ): string | null => {
        if (errors instanceof yup.ValidationError) {
            return translateErrorMessage(errors.message)
        }

        let error = errors && name.split('.').reduce((o, i) => o && o[i], errors)
        if (!error) return null

        if (Array.isArray(error)) {
            error = Object.values(error)[0]
        }

        let errorKey = error?.message as string
        if (typeof error.message === 'object') {
            // @ts-ignore
            errorKey = error?.message?.key
        }

        return translateErrorMessage(errorKey)
    }

    const translateErrorMessage = (errorKey: string) => {
        const intlKey = intl[errorKey]
        return intlKey ? formatMessage(intlKey) : errorKey
    }

    return (
        <YupProviderContext.Provider value={{
            yup: yup as YupType,
            resolver: yupResolver,
            errorMessage
        }}>
            {children}
        </YupProviderContext.Provider>
    )
}

export const useYup = () => {
    const context = useContext(YupProviderContext)

    if (!context) {
        console.warn(`useYup must be used within the YupProvider`)
    }
    return context
}
