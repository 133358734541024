import React from 'react'

interface UICloseIconProps {
    size?: 'small' | 'medium' | 'large'
}

const sizeVariants = {
    small: 'ec-w-4 ec-h-4',
    medium: 'ec-w-5 ec-h-5',
    large: 'ec-w-6 ec-h-6'
}

export const UICloseIcon = (
    { size = 'medium' }: UICloseIconProps
) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
             className={sizeVariants[size]}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
    )
}
