import React, {useEffect} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {TouchBackend} from 'react-dnd-touch-backend'
import {LargeDraggableItem} from "./LargeDraggableItem";
import {useWindowSize} from "@ui/hooks";
import {useSortingListState} from "@module/SortingLists";
import {useIntl} from "react-intl";

export const LargeList = () => {

    const {isMobile} = useWindowSize();
    const {items, moveItem, findItem, sortItemsByName, sortItemsByLabel, listData} = useSortingListState();
    const {formatMessage} = useIntl();
    const [isUpDownName, setIsUpDownName] = React.useState<boolean>(false);
    const [isUpDownLabel, setIsUpDownLabel] = React.useState<boolean>(false);

    let itemsLabels = listData.map((value) => {
        return value.label ? value.label : null;
    }).filter((value) => value !== null);

    useEffect(() => {
        setIsUpDownLabel(false);
    }, [isUpDownName]);

    useEffect(() => {
        setIsUpDownName(false);
    }, [isUpDownLabel]);

    const Order = ({isUpDown}) => {
        return (
            <div className={'ec-text-xs '}>
                <div className={'ec-flex ec-text-black'}>
                    {!isUpDown && <div className={'ec-flex ec-flex-col ec-text-[9px] ec-leading-tight'}>
                        <div>
                            A
                        </div>
                        <div>
                            Z
                        </div>
                    </div>}
                    {isUpDown && <div className={'ec-flex ec-flex-col ec-text-[9px] ec-leading-tight'}>
                        <div>
                            Z
                        </div>
                        <div>
                            A
                        </div>
                    </div>}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="ec-w-6 ec-h-6 ec-ml-[-5px] ec-mt-[-1px]">
                        <path d="M12 3l-4 4h3v6h2V7h3l-4-4z" />
                        <path d="M12 21l4-4h-3v-6h-2v6h-3l4 4z" />
                    </svg>

                </div>
            </div>
        )
    };

    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <div className="ec-flex ec-items-center ec-justify-between ec-p-4 ec-border-b ec-border-b-gray-lighter">
                <div className={'ec-flex ec-w-96'}>
                    {!isMobile && <div className={'ec-w-16'}>
                        {
                            formatMessage({
                                id: 'sort.header.Position',
                                defaultMessage: 'Position'
                            })
                        }
                    </div>}
                    <div className={'ec-flex ec-cursor-pointer'}
                         onClick={() => {
                                setIsUpDownName(!isUpDownName);
                                sortItemsByName(isUpDownName)
                         }}
                         title={ formatMessage({
                        id: 'sort.header.title.sort.p',
                        defaultMessage: 'Sort by Product Name'
                    })}>
                        <div className={'ec-pr-2'}>
                            {
                                formatMessage({
                                    id: 'sort.header.Product',
                                    defaultMessage: 'Product'
                                })
                            }
                        </div>
                       <Order isUpDown={isUpDownName}/>
                    </div>
                </div>
                <div className={'ec-flex ec-cursor-pointer'}
                        onClick={() => {
                            setIsUpDownLabel(!isUpDownLabel);
                            sortItemsByLabel(isUpDownLabel)
                        }}
                     title={
                    formatMessage({
                        id: 'sort.header.title.sort.l',
                        defaultMessage: 'Sort by Label Name'
                    })
                }>
                    <div className={'ec-pr-2'}>
                        {
                            formatMessage({
                                id: 'sort.header.Label',
                                defaultMessage: 'Label'
                            })
                        }
                    </div>
                    {itemsLabels.length > 0 && <Order isUpDown={isUpDownLabel}/>}
                </div>
            </div>

            <div>
                {items.map((item, index) => (
                    <LargeDraggableItem
                        index={index}
                        items={items}
                        key={item.id}
                        id={item.id}
                        visualIndex={item.visualIndex}
                        moveItem={moveItem}
                        findItem={findItem}
                    />
                ))}
            </div>
        </DndProvider>
    );
};
