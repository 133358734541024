import React, {useEffect} from 'react';
import {useSortingListState} from "@module/SortingLists";
import {UIInput} from "@module/Ui";
import {useIntl} from "react-intl";

export const LargeItemList = () => {

    const {
        appConfig: {visibleData},
        listData,
        selectedKey,
        setSelectedKey,
        handleCheckboxChange,
        handleSearchChange,
        checkedLabels
    } = useSortingListState();
    const {formatMessage} = useIntl();

    const searchLabel = formatMessage({
        id: 'sort.order.search',
        defaultMessage: 'Search for Product by Name, MFG #, Part # or Label'
    });

    const noLabel = formatMessage({
        id: 'sort.order.noLabel',
        defaultMessage: '- no label -'
    });

    useEffect(() => {
        if(!selectedKey){
            setSelectedKey(Object.keys(visibleData)[0])
        }
    }, [selectedKey])

    let itemsLabels = listData.map((value) => {
       return value.label ? value.label : null;
    }).filter((value) => value !== null);

    const hasItemsWithoutLabel = listData.some(item => !item.label);

    if (hasItemsWithoutLabel) {
        itemsLabels = [...itemsLabels, noLabel];
    }

    return (
        <div>
            <div className={'ec-flex'}>
                {itemsLabels.map((label, index) => {
                    const checkboxId = `field-${index}`;
                    return (
                        <div className={'field-item ec-px-4'}>
                            <input
                                type="checkbox"
                                id={checkboxId}
                                checked={!!checkedLabels[index]}
                                onChange={() => handleCheckboxChange(index, label)}
                            />
                            <label htmlFor={checkboxId}>{label}</label>
                        </div>
                    );
                })}
            </div>

            <div className={'ec-w-96'}>
                <UIInput label={searchLabel} size={'medium'} onChange={handleSearchChange}/>
            </div>
        </div>
    );
};
