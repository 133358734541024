/**
 * Yup localization and i18n
 * @see https://github.com/jquense/yup#localization-and-i18n
 */
export const localeConfig = {
    mixed: {
        default: 'mixedDefault',
        required: 'mixedRequired'
    },
    string: {
        email: 'stringEmail'
    }
}
