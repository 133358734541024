import React, { InputHTMLAttributes } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { UIFormError, UIInputLabel } from '@module/Ui'
import clsx from 'clsx'

interface UIInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'className'> {
    size?: 'small' | 'medium' | 'large'
    align?: 'left' | 'center' | 'right'
    label?: string
    error?: string
    register?: UseFormRegisterReturn<string>
}

const sizeVariants = {
    small: 'ec-text-xs ec-leading-5 ec-h-[1.75rem]',
    medium: 'ec-text-sm ec-leading-7 ec-h-[2.25rem]',
    large: 'ec-text-sm ec-leading-8 ec-h-[2.5rem]'
}

const alignVariants = {
    left: 'ec-text-left',
    center: 'ec-text-center',
    right: 'ec-text-right'
}

export const UIInput = (
    {
        size = 'medium',
        align = 'left',
        placeholder = ' ',
        error,
        label,
        register,
        ...attributes
    }: UIInputProps
) => (
    <div className={clsx(
        `ec-relative`,
        { 'ec-mt-4': !!label }
    )}>
        <input className={clsx(
            'ec-text-sm ec-leading-7 ec-border ec-border-solid ec-border-gray-dark ec-appearance-none',
            `focus:ec-ring-0 ec-peer ec-px-2 ec-w-full ${alignVariants[align]} ${sizeVariants[size]}`,
            {
                'ec-bg-white': !attributes.disabled,
                'ec-bg-gray-lighter ec-cursor-not-allowed': attributes.disabled
            }
        )}
               placeholder={placeholder}
               aria-invalid={!!error}
               aria-errormessage={error}
               {...register || {}}
               {...attributes}/>
        <UIInputLabel label={label}/>
        <UIFormError error={error}/>
    </div>
)
