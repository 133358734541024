import { MessageDescriptor } from 'react-intl'

export interface UIInputListIntl {
    add: MessageDescriptor
    delete: MessageDescriptor
}

export default {
    add: {
        id: 'ui.input.list.add',
        defaultMessage: 'Add'
    },
    delete: {
        id: 'ui.input.list.delete',
        defaultMessage: 'Delete'
    }
}
