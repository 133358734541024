import React from 'react';
import {ReorderActionModal} from "@module/SortingLists";
import {IntlProvider} from "react-intl";
import {ReorderAction} from "./ReorderAction";
import {SortingListProvider} from "@module/SortingLists/components/state";

interface ReorderAppPropsInterface {
    appConfig: {
        withButton: boolean,
        visibleData: any,
        isModal: boolean
    }
}

export const ReorderApp = (config: ReorderAppPropsInterface) => {

    return (
        <IntlProvider locale={'en'}>
            <SortingListProvider config={config}>
                {config?.appConfig?.isModal && <ReorderActionModal/>}
                {!config?.appConfig?.isModal && <ReorderAction/>}
            </SortingListProvider>
        </IntlProvider>
    );
};