import { MessageDescriptor } from 'react-intl'

export interface UIInputChipIntl {
    placeholder: MessageDescriptor
}

export default {
    placeholder: {
        id: 'ui.input.chip.placeholder',
        defaultMessage: 'Type here...'
    }
}
