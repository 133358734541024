import React, { ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'

interface UIButtonIconProps extends UIOmit<ButtonHTMLAttributes<HTMLButtonElement>> {
    ariaLabel: string
    size?: 'small' | 'medium' | 'large'
}

const sizeVariant = {
    small: 'ec-p-1',
    medium: 'ec-p-2',
    large: 'ec-p-2.5'
}

export const UIButtonIcon = (
    {
        ariaLabel,
        size = 'medium',
        children,
        ...attributes
    }: UIButtonIconProps
) => (
    <button type="button" className={clsx(
        `ec-text-gray-darker ${sizeVariant[size]}`,
        {
            'hover:ec-text-red': !attributes.disabled,
            'ec-opacity-50 ec-cursor-not-allowed': attributes.disabled
        }
    )} {...attributes}>
        {children}
        <span className="ec-sr-only">
            {ariaLabel}
        </span>
    </button>
)
