import React from 'react'
import { FormProvider } from 'react-hook-form'
import { DeliveryMethods } from '../Checkout/DeliveryMethods'
import { PaymentMethods } from '../Checkout/PaymentMethods'
import { OrderConfirmationEmails } from '../Checkout/OrderConfirmationEmails'
import { InactiveCustomers } from '../Preferences/InactiveCustomers'
import { usePreferencesState } from '../../state/index'
import { ResidioProPerksNumber } from './ResidioProPerksNumber'
import { UIContainerSide, UIPageLoader, UIPanel, UIPanelBody, UIStack } from '@ui/components'
import { SaveChangesButton } from './SaveChangesButton'
import { ShareCartPreferences } from './ShareCartPreferences'
import { UIContainer } from '@ui/components/Layout/UIContainer/UIContainer'
import { UIContainerContent } from '@ui/components/Layout/UIContainer/UIContainerContent'

export const CustomerPreferences = () => {
    const { onSubmit, form, query, preferences } = usePreferencesState()

    if (query.isLoading) {
        return (<UIPageLoader/>)
    }

    if (query.isError) {
        return (
            <div>
                <div>{query.error.message}</div>
                {'Try refreshing the page or contact us if the problem persists.'}
            </div>
        )
    }

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <UIContainer>
                    <UIContainerContent>
                        <UIStack spacing="large">
                            <UIPanel>
                                <UIPanelBody>
                                    <DeliveryMethods methods={preferences.meta.checkout.delivery_method_list}/>
                                    <PaymentMethods methods={preferences.meta.checkout.payment_method_list}/>
                                    <OrderConfirmationEmails/>
                                </UIPanelBody>
                            </UIPanel>

                            {preferences.data.inactiveCustomers.isInactiveCustomersEnabled &&
                                <InactiveCustomers/>
                            }

                            {preferences.data?.residioProPerks?.visible &&
                                <ResidioProPerksNumber/>
                            }

                            <ShareCartPreferences/>
                        </UIStack>
                    </UIContainerContent>
                    <UIContainerSide>
                        <SaveChangesButton/>
                    </UIContainerSide>
                </UIContainer>
            </form>
        </FormProvider>
    )
}

