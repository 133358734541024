import React, { HTMLAttributes } from 'react'
import clsx from 'clsx'

interface UIBackdropProps extends UIOmit<HTMLAttributes<HTMLDivElement>> {
    color?: 'dark' | 'light'
}

const colorVariants = {
    dark: 'ec-bg-gray-darker/25',
    light: 'ec-bg-white/60'
}

export const UIBackdrop = (
    { color = 'dark', children, ...attributes }: UIBackdropProps
) => (
    <div className={clsx(
        colorVariants[color],
        'ec-fixed ec-inset-0 ec-flex ec-items-center ec-justify-center'
    )} {...attributes}>
        {children}
    </div>
)
