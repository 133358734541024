import React, {FC, ReactElement} from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { PreferencesProvider } from '../../state/index';
import { CustomerPreferences } from './CustomerPreferences';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {IntlProvider} from "react-intl";
import { YupProvider } from '../../../Yup'
import { getQueryClient } from '../../../../models/api/queryClient'

const queryClient = getQueryClient()

export interface AppProps {
    config: object;
}

export const PurePreferencesApp: FC<AppProps> = ({config}: AppProps): ReactElement => {
    return (
        <IntlProvider locale={'en'}>
            <QueryClientProvider client={queryClient}>
                <YupProvider>
                    <PreferencesProvider jsConfig={config}>
                        <CustomerPreferences/>
                        <ToastContainer autoClose={8000}/>
                    </PreferencesProvider>
                </YupProvider>
             </QueryClientProvider>
        </IntlProvider>
    );
};
