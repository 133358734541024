import React from 'react'
import {
    UIFieldset,
    UIFieldsetSpan,
    UIInput,
    UIInputList,
    UIInputListColumn,
    UIPanel,
    UIPanelBody
} from '@ui/components'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { PreferencesFormData, usePreferencesState } from '@module/CustomerPreferences'
import { useIntl } from 'react-intl'
import { useYup } from '@module/Yup'
import intl from './intl'

export const ShareCartPreferences = () => {
    const { register, formState: { errors }, control } = useFormContext<PreferencesFormData>()
    const { isLoading } = usePreferencesState()
    const { formatMessage } = useIntl()
    const { errorMessage } = useYup()

    const columns: UIInputListColumn[] = [
        {
            label: formatMessage(intl.shareCartName),
            renderer: (field, index) => (
                <UIInput key={field.id}
                         disabled={isLoading}
                         register={register(`shareCart.contacts.${index}.name`)}
                         error={errorMessage(errors, `shareCart.contacts.${index}.name`)}/>
            )
        },
        {
            label: formatMessage(intl.shareCartContact),
            renderer: (field, index) => (
                <UIInput key={field.id}
                         disabled={isLoading}
                         register={register(`shareCart.contacts.${index}.contact`)}
                         error={errorMessage(errors, `shareCart.contacts.${index}.contact`)}/>
            )
        }
    ]

    // @ts-ignore
    const { fields, append, remove } = useFieldArray({
        name: 'shareCart.contacts',
        control
    })

    return (
        <UIPanel>
            <UIPanelBody>
                <UIFieldset legend={formatMessage(intl.shareCartTitle)}>
                    <UIFieldsetSpan>
                        <UIInputList columns={columns}
                                     disabled={isLoading}
                                     data={fields}
                                     limit={10}
                                     onAdd={() => append({ name: '', contact: '' })}
                                     onDelete={({ index }) => remove(index)}/>
                    </UIFieldsetSpan>
                </UIFieldset>
            </UIPanelBody>
        </UIPanel>
    )
}
