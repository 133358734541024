import React, { HTMLAttributes } from 'react'

interface UIFormErrorProps extends UIOmit<HTMLAttributes<HTMLParagraphElement>> {
    error: string | undefined | null
}

export const UIFormError = (
    { error, ...attributes }: UIFormErrorProps
) => (
    error
        ? <p className="ec-my-1 ec-text-xs ec-text-red" role="alert" {...attributes}>{error}</p>
        : null
)
