import { defineMessages } from 'react-intl'

export default defineMessages({
    shareCartTitle: {
        id: 'shareCart.title',
        defaultMessage: 'Default Share Cart Contacts'
    },
    shareCartName: {
        id: 'shareCart.inputList.name',
        defaultMessage: 'Name'
    },
    shareCartContact: {
        id: 'shareCart.inputList.contact',
        defaultMessage: 'Email or Phone Number'
    },
    shareCartContactErrorMessage: {
        id: 'shareCart.inputList.contact.error',
        defaultMessage: 'Invalid email or phone number.'
    }
})
