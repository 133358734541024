import React, { HTMLAttributes } from 'react'

interface UIGridProps extends UIOmit<HTMLAttributes<HTMLDivElement>> {
    spacing?: 1 | 2 | 3 | 4
}

const spacingVariants = {
    1: 'ec-gap-x-1 ec-gap-y-1',
    2: 'ec-gap-x-2 ec-gap-y-2',
    3: 'ec-gap-x-2 ec-gap-y-3',
    4: 'ec-gap-x-2 ec-gap-y-4'
}

export const UIGrid = (
    { spacing = 2, children, ...attributes }: UIGridProps
) => {
    return (
        <div className={`ec-mt-6 ec-grid ec-grid-cols-1 sm:ec-grid-cols-6 ${spacingVariants[spacing]}`}
             {...attributes}>
            {children}
        </div>
    )
}
