import { getCustomerDataQuery } from '@module/Customer'
import { CustomerPreferencesInterface, GraphQlPreferences } from '@module/CustomerPreferences'
import { request } from '@model/api'

export type CustomerData = Customer
    & { permission: CustomerPermissions }
    & { preferences: CustomerPreferencesInterface }

type CustomerDataResponse = GraphQlPreferences
    & { customerPermission: CustomerPermissions }
    & { customer: Customer }

interface CustomerPermissions {
    proposal: boolean
    saveCart: boolean
    shareCart: boolean
}

export const getCustomerData = async (): Promise<CustomerData> => {
    const { data: { data } } = await request<CustomerDataResponse>(getCustomerDataQuery)

    return {
        ...data.customer,
        permission: data.customerPermission,
        preferences: data.preferences.data
    }
}
