import React, { PropsWithChildren, useEffect, useRef } from 'react'

/**
 * @source: https://codepen.io/brundolf/pen/dvoGyw
 * @source: https://css-tricks.com/using-css-transitions-auto-dimensions/
 */

type UIExpandProps = PropsWithChildren & {
    expand?: boolean
}

export const UIExpand = (
    { children, expand = true }: UIExpandProps
) => {
    const elementRef = useRef<HTMLDivElement>()
    const isMounted = useRef(false)

    useEffect(() => {
        if (isMounted.current) {
            expand
                ? expandSection()
                : collapseSection()
        } else {
            isMounted.current = true

            if (!expand) {
                elementRef.current.style.height = '0px'
                collapseSection()
                elementRef.current.style.display = null
            } else {
                elementRef.current.style.height = '0px'
                elementRef.current.style.display = null
                expandSection()
            }
        }
    }, [expand])

    const collapseSection = () => {
        if (!elementRef || !elementRef.current) return

        const sectionHeight = elementRef.current.scrollHeight
        const elementTransition = elementRef.current.style.transition
        elementRef.current.style.transition = ''

        requestAnimationFrame(() => {
            elementRef.current.style.height = sectionHeight + 'px'
            elementRef.current.style.transition = elementTransition

            requestAnimationFrame(() => {
                elementRef.current.style.height = 0 + 'px'
            })
        })

        elementRef.current.setAttribute('data-collapsed', 'true')
    }

    const expandSection = () => {
        const sectionHeight = elementRef.current.scrollHeight
        elementRef.current.style.height = sectionHeight + 'px'

        elementRef.current.addEventListener('transitionend', () => {
            elementRef.current.style.height = null
        }, { once: true })
    }

    return (
        <div ref={elementRef}
             style={{ display: 'none' }}
             className="ec-overflow-hidden ec-h-auto ec-transition-height ec-ease-in-out">
            {children}
        </div>
    )
}
