import { usePreferencesState } from '@module/CustomerPreferences'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { UIFieldset, UIFieldsetSpan, UIInput, UIPanel, UIPanelBody, UIToggle } from '@ui/components'
import { useYup } from '@module/Yup'

export const InactiveCustomers = () => {
    const { register, watch, setValue, formState: { errors } } = useFormContext()
    const { isLoading } = usePreferencesState()
    const { errorMessage } = useYup()
    const { formatMessage } = useIntl()

    const enabledValue = watch('inactiveCustomers.enabled')
    const offsetValue = watch('inactiveCustomers.offset')

    useEffect(() => {
        if (!enabledValue) {
            setValue('inactiveCustomers.offset', '0')
        }
    }, [enabledValue])

    return (
        <UIPanel>
            <UIPanelBody>
                <UIFieldset legend={formatMessage({
                    id: 'inactiveCustomers.title',
                    defaultMessage: 'Inactive Customer Email'
                })}>
                    <UIFieldsetSpan>
                        <UIToggle
                            register={register('inactiveCustomers.enabled')}
                            name="inactiveCustomers.enabled"
                            disabled={isLoading}
                            label={formatMessage({
                                id: 'send.email.label.toggle',
                                defaultMessage: 'Send Email'
                            })}/>
                    </UIFieldsetSpan>
                    <UIFieldsetSpan>
                        {enabledValue && <div>
                            <UIInput
                                register={register('inactiveCustomers.offset', { valueAsNumber: true })}
                                type={'number'}
                                value={offsetValue}
                                disabled={isLoading}
                                name={'inactiveCustomers.offset'}
                                label={formatMessage({
                                    id: 'send.email.label.toggle',
                                    defaultMessage: 'Days Inactive'
                                })}
                                error={errorMessage(errors, 'inactiveCustomers.offset')}/>
                        </div>}
                    </UIFieldsetSpan>
                </UIFieldset>

            </UIPanelBody>
        </UIPanel>
    )
}
