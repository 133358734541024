import React, { InputHTMLAttributes } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import clsx from 'clsx'

interface UIToggleProps extends UIOmit<InputHTMLAttributes<HTMLInputElement>> {
    label?: string
    register?: UseFormRegisterReturn<string>
}

export const UIToggle = (
    { label, register, ...attributes }: UIToggleProps
) => (
    <label className={clsx(
        'ec-relative ec-inline-flex ec-items-center',
        {
            'ec-cursor-pointer': !attributes.disabled,
            'ec-cursor-not-allowed': attributes.disabled
        }
    )}>
        <input type="checkbox"
               className="ec-sr-only ec-peer"
               {...register || {}}
               {...attributes}/>
        <div className={clsx(
            'ec-w-10 ec-h-6 ec-bg-gray ec-rounded-full ec-peer peer-checked:after:ec-translate-x-[calc(100%-4px)]',
            'peer-checked:after:ec-border-white after:ec-absolute after:ec-top-[2px] after:ec-left-[2px]',
            'after:ec-bg-white after:ec-rounded-full after:ec-h-5 after:ec-w-5 after:ec-transition-all',
            'peer-checked:ec-bg-green',
            { 'ec-opacity-50': attributes.disabled }
        )}>
        </div>
        {label &&
            <span className="ec-ml-2 ec-text-sm">{label}</span>
        }
    </label>
)
