import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import update from "immutability-helper";

interface SortingListProviderPropsInterface extends PropsWithChildren{
    config: ConfigInterface
}

export interface AppConfigInterface {
    withButton: boolean,
    visibleData: any,
    isModal: boolean
}

interface ConfigInterface {
    appConfig: AppConfigInterface
}

interface SortingListStateContextInterface {
    listData: any
    appConfig: AppConfigInterface
    open: boolean
    setOpen(open: boolean): void
    handleDataUpdate(): void
    setListData(listData: any): void
    selectedKey: string
    setSelectedKey(selectedKey: string): void
    handleCheckboxChange(id: string, label:string): void
    handleSearchChange(event: any): void
    checkedLabels: any,
    items: any,
    setItems(items: any): void,
    moveItem(dragIndex: number, hoverIndex: number): void,
    findItem(id: string): any
    isContentButtonVisible: boolean
    setIsContentButtonVisible(isContentButtonVisible: boolean): void
    isOrderChanged: boolean,
    sortItemsByName: any,
    sortItemsByLabel: any
}

const SortingListStateContext = React.createContext<SortingListStateContextInterface | null>(null);

export const SortingListProvider = ({children, config}: SortingListProviderPropsInterface) => {

    const [appConfig, setAppConfig] = useState<AppConfigInterface>(config.appConfig);
    const [open, setOpen] = React.useState<boolean>(false)
    const [listData, setListData] = React.useState<any>(null)
    const [selectedKey, setSelectedKey] = useState<string>(null);

    const [checkedLabels, setCheckedLabels] = useState({});
    const [checkedLabelsNames, setCheckedLabelsNames] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [items, setItems] = useState([]);
    const [isContentButtonVisible, setIsContentButtonVisible] = useState(false);
    const [initialItemsOrder, setInitialItemsOrder] = useState([]);
    const [isOrderChanged, setIsOrderChanged] = useState(false);

    const handleCheckboxChange = (id, label) => {
        setCheckedLabels(prev => {
            const isChecked = !prev[id];

            const updated = {
                ...prev,
                [id]: isChecked,
            };

            setCheckedLabelsNames(names => {
                if (isChecked) {
                    return names.includes(label) ? names : [...names, label];
                } else {
                    return names.filter(name => name !== label);
                }
            });

            return updated;
        });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleDataUpdate = () => {
        window.dispatchEvent(new CustomEvent('listDataUpdateEvent', {
            detail: listData
        }));
        setOpen(!open)
    }

    const updateItemVisibility = () => {
        const updatedListData = listData.map(item => {
            const matchesSearch = searchTerm === '' || item.name.toLowerCase().includes(searchTerm.toLowerCase());

            let isChecked = false;
            if (checkedLabelsNames.length === 0) {
                isChecked = true;
            } else {
                if (item.label && checkedLabelsNames.includes(item.label)) {
                    isChecked = true;
                } else if (!item.label && checkedLabelsNames.includes('- no label -')) {
                    isChecked = true;
                }
            }

            const shouldBeVisible = matchesSearch && isChecked;

            return {
                ...item,
                hidden: !shouldBeVisible,
            };
        });

        setItems(updatedListData);
    };

    const moveItem = useCallback((dragIndex, hoverIndex) => {

        const dragItem = items[dragIndex];
        let newItems = update(items, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragItem],
            ],
        });

        newItems = newItems.map((item, index) => ({
            ...item,
            visualIndex: index + 1
        }));
        setListData(newItems);
        setItems(newItems);
        const currentOrder = newItems.map(item => item.id);
        const orderChanged = currentOrder.some((id, index) => id !== initialItemsOrder[index]);
        setIsOrderChanged(orderChanged);
    }, [items]);

    const findItem = useCallback((id) => {
        const item = items.find((item) => item.id === id);
        return {
            item,
            index: items.indexOf(item),
        };
    }, [items]);

    const sortItemsByName = (azUp = true) => {

        const sortedItems = [...items].sort((a, b) => {
            const nameA = a.name.toUpperCase() || '';
            const nameB = b.name.toUpperCase() || '';

            if (azUp) {
                return nameA.localeCompare(nameB);
            }

            if (!azUp) {
                return nameB.localeCompare(nameA);
            }
        });

        const newItems = sortedItems.map((item, index) => ({
            ...item,
            visualIndex: index + 1
        }));

        setListData(newItems);
        setItems(newItems);
        setIsOrderChanged(true);
    };

    const sortItemsByLabel = (azUp = true) => {
        const sortedItems = [...items].sort((a, b) => {
            if (!a.label && !b.label) return 0;
            if (!a.label) return 1;
            if (!b.label) return -1;

            if (azUp) {
                return a.label.toUpperCase().localeCompare(b.label.toUpperCase());
            }

            if (!azUp) {
                return b.label.toUpperCase().localeCompare(a.label.toUpperCase());
            }
        });

        const newItems = sortedItems.map((item, index) => ({
            ...item,
            visualIndex: index + 1
        }));

        setListData(newItems);
        setItems(newItems);
        setIsOrderChanged(true);
    };

    useEffect(() => {
        const handleListData = (event) => {
            const updatedWithVisual = event.detail.map((item, index) => ({
                ...item,
                visualIndex: index + 1
            }));
            setListData(updatedWithVisual)
            setItems(updatedWithVisual);
            setInitialItemsOrder(updatedWithVisual.map(item => item.id));
            setIsOrderChanged(false);
        };

        window.addEventListener('listDataGetEvent', handleListData);

        return () => {
            window.removeEventListener('listDataGetEvent', handleListData);
        };
    }, []);

    useEffect(() => {
        const handleOpenEvent = () => {
            setOpen(true);
        };

        window.addEventListener('reorderOpenEvent', handleOpenEvent);

        return () => {
            window.removeEventListener('reorderOpenEvent', handleOpenEvent);
        };
    }, [])

    useEffect(() => {
        if(!listData) return;

        updateItemVisibility();
    }, [searchTerm, listData, checkedLabels, checkedLabelsNames]);

    return (
        <SortingListStateContext.Provider
            value={{
                appConfig,
                open,
                setOpen,
                listData,
                setListData,
                handleDataUpdate,
                selectedKey,
                setSelectedKey,
                handleCheckboxChange,
                handleSearchChange,
                checkedLabels,
                items,
                setItems,
                moveItem,
                findItem,
                isContentButtonVisible,
                setIsContentButtonVisible,
                isOrderChanged,
                sortItemsByName,
                sortItemsByLabel
            }}>
            {children}
        </SortingListStateContext.Provider>
    );
};

export const useSortingListState = () => {
    const context = React.useContext(SortingListStateContext);
    if (!context) {
        console.warn('useSortingListState must be used within the SortingListProvider');
    }
    return context;
}
