import clsx from 'clsx'
import React, { HTMLAttributes, ReactNode } from 'react'

interface UIInputLabelProps extends UIOmit<HTMLAttributes<HTMLLabelElement>> {
    label: ReactNode | undefined | null
    active?: boolean
}

export const UIInputLabel = (
    { label, active, ...attributes }: UIInputLabelProps
) => {
    if (!label) return null

    if (active) {
        return (
            <label className={clsx(
                'ec-absolute ec-left-0 ec-text-gray-darker/75 ec-pointer-events-none ec-text-xs ec-font-medium',
                'ec-translate-x-0 -ec-translate-y-4 ec-top-0'
            )} {...attributes}>
                {label}
            </label>
        )
    }

    return (
        <label className={clsx(
            'ec-absolute ec-transition-all ec-duration-300 ec-left-0 ec-text-gray-darker/75',
            'ec-pointer-events-none ec-translate-x-0 -ec-translate-y-4 ec-text-xs ec-font-medium',
            'peer-placeholder-shown:ec-translate-x-2 peer-placeholder-shown:ec-translate-y-2',
            'peer-placeholder-shown:ec-text-sm peer-placeholder-shown:ec-font-normal',
            'peer-focus:ec-translate-x-0 peer-focus:-ec-translate-y-4 peer-focus:ec-text-xs',
            'peer-focus:ec-font-medium'
        )} {...attributes}>
            {label}
        </label>
    )
}
