import { defineMessages } from 'react-intl'

export default defineMessages({
    mixedDefault: {
        id: 'yup.default',
        defaultMessage: 'Invalid field.'
    },
    mixedRequired: {
        id: 'yup.required',
        defaultMessage: 'This is a required field.'
    },
    stringRangeLength: {
        id: 'yup.string.range.length',
        defaultMessage: 'Please enter a value between {min} and {max} characters long.'
    },
    stringPhoneNumber: {
        id: 'yup.string.phone.number',
        defaultMessage: 'Invalid phone number.'
    },
    stringEmail: {
        id: 'yup.string.email',
        defaultMessage: 'Please enter a valid email address.'
    },
    numberRange: {
        id: 'yup.number.range',
        defaultMessage: 'Please enter a value greater than {moreThan} and less than {lessThan}.'
    },
    numberRangeEqual: {
        id: 'yup.number.range.equal',
        defaultMessage: 'Please enter a value between {min} and {max}.'
    }
})
